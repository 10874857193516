import AsYouFont1 from "./Images/AsYouFont1.png";
import AsYouPic1 from "./Images/AsYoupic1.jpg";
import AsYouPic2 from "./Images/AsYouPic2.jpg";
import AsYouPic3 from "./Images/AsYouPic3.jpg";
import AsYouPic4 from "./Images/AsYouPic4.png";
import AsYouPic5 from "./Images/AsYouPic5.jpg";
import AsYouPic6 from "./Images/AsYouPic6.jpg";
import AsYouPic7 from "./Images/AsYouPic7.jpg";
import AsYouGif1 from "./Images/AsYouGif1.gif";
import AsYouGif2 from "./Images/AsYouGif2.gif";

const AsYouImages = {
  AsYouFont1,
  AsYouPic1,
  AsYouPic2,
  AsYouPic3,
  AsYouPic4,
  AsYouPic5,
  AsYouPic6,
  AsYouPic7,
  AsYouGif1,
  AsYouGif2,
};

export default AsYouImages;
