import rock from "./Images/rock.png";
import paper from "./Images/paper.png";
import scissors from "./Images/scissors.png";
import roach from "./Images/roachmovementWHITE_1.png";

const RPSImages = {
  rock,
  paper,
  scissors,
  roach,
};

export default RPSImages;
