import AhShitFont from "./Images/AhShitFont.png";
import AhShitPic1 from "./Images/AhShitPic1.jpg";
import AhShitPic2 from "./Images/AhShitPic2.jpg";

const AhShitImages = {
  AhShitFont,
  AhShitPic1,
  AhShitPic2,
};

export default AhShitImages;
