import friendshipFont from "./Images/FriendshipFont.png";
import friendshipPic1 from "./Images/FriendshipPic1.jpg";
import friendshipPic2 from "./Images/FriendshipPic2.jpg";
import friendshipPic3 from "./Images/FriendshipPic3.jpg";
import friendshipPic4 from "./Images/FriendshipPic4.jpg";
import friendshipPic5 from "./Images/FriendshipPic5.jpg";
import friendshipPic6 from "./Images/FriendshipPic6.jpg";

const FriendshipImages = {
  friendshipFont,
  friendshipPic1,
  friendshipPic2,
  friendshipPic3,
  friendshipPic4,
  friendshipPic5,
  friendshipPic6,
};

export default FriendshipImages;
