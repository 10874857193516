const Fortune = {
  1: "อาจารย์รำพึง ทั้งหลายทำตาม จะได้เป็นความ ครั้นเรียกมาถาม จักแก้ขยักเขย่า",
  2: "สุภาพ นุ่งผ้ามีลาภ ถึงเงื้อมมือตน",
  3: "สมบูรณ์ ดีตามลำเนาว์ หนุ่มน้อยนงเยาว์ ผูกรักไมตรี",
  4: "ได้ทุกข์โข โรคาย่ำยี",
  5: "นั้นดี จักได้ข้าคน",
  6: "อุบาทว์ อาจารย์ห้ามขาด",
  7: "อำพล ตนจักได้ดี",
  8: "นุ่งผ้าจักเวทนา พลันม้วยชีวี",
  9: "มิดี จะได้ร้อนรน",
  10: "เป็นทุกข์ทน กันมา พลันม้วยงายชนม์",
  11: "ร้อนรน คือหนึ่งไฟลน",
  12: "อำพล ได้ลาภดังใจ",
  13: "นุ่งผ้างามนัก สวัสดีมีชัย",
  14: "เป็นทุกข์ใหญ่",
  15: "ร้อนใจ ความไข้ย่ำยี",
  16: "อาจารย์ว่านุ่งมิดี",
  17: "นั้นดี จักได้ข้าคน",
  18: "อำพล ได้ลาภดังใจ",
  19: "จักได้ช้างม้า ข้าคน ชายหญิง",
  20: "เกิดผล จักได้ข้าวของ",
  21: "กาลปักษ์ นุ่งผ้างามนัก จักได้เงินทอง",
  22: "จักได้ สมบัติทั้งผอง",
  23: "ลำยอง ได้เมียสมตน",
  24: "นรลักษณ์ นุ่งผ้างามนัก ประจักษ์ทุกคน",
  25: "จักได้ลาภล้น",
  26: "ร้อนรน จักไข้พึงนาน",
  27: "จักเกิดเชี่ยวชาญ",
  28: "ดีนัก เย็นใจสำราญ",
  29: "บ่นาน มักง่าย อาจารย์กล่าวไว้",
  30: "สรรโลกา",
};

export default Fortune;
